/* XL */
@media (max-width: 1400px) {

  .conversation-page .right-column{ position: absolute; top: 0.1rem; right: -42rem; bottom: 0rem; z-index: 10;}
  .conversation-page .right-column.show{ right: -0rem;}
  .conversation-page .right-column .close-column{display: none; cursor: pointer; position: absolute; width: 1.6rem; top: 0rem; bottom: 0rem; left: -1.6rem; align-items: center; justify-content: center; background-color: #F5F5F5; opacity: 0.5;}
  .conversation-page .right-column .close-column:hover{opacity: 1;}
  .conversation-page .right-column.show .close-column{display: flex;}
  .conversation-page .middle-column .header .avatar-block .conversation-state{cursor: pointer;}    
  .conversation-page .middle-column .header .actions .item.toggle-right-panel{ display: flex;}
  
}