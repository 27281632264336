/* MD */
@media (max-width: 992px) {
    .top-panel .logo{display: none;}
    .top-panel .logo.symbols{display: block;}
    .top-panel .menu-wrapper{position: relative; left: auto; right: auto; margin-left: auto;  pointer-events: auto;}
    .top-panel .mobile-menu-link{display: flex;cursor: pointer;}
    
    .top-panel .main-menu { display: none; width: 21.4rem; flex-direction: column; position: absolute; z-index: 100; top:4.5rem; left:0rem; background-color: #ffffff; border-radius: 5px;box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1); padding: 1.6rem; border-radius: 8px; cursor: default;}
    .top-panel .main-menu.show {display: flex;}
    .top-panel .main-menu .menu { display: flex; flex-direction: column; margin-left: 0rem; margin-right: 0rem; align-items: stretch;}

    .top-panel .main-menu .arrow {display: block; width: 1rem; height: 1rem; transform: rotate(45deg); position: absolute;  z-index: 100; background-color: #ffffff; top: -0.5rem; left: 5.2rem}
    .top-panel .main-menu.show{display:flex; }
    .top-panel .main-menu .menu-item {display:flex; flex-direction: row; align-items: center; padding: 0.6rem 1.6rem; font-family: 'ABC Normal Medium'; font-style: normal; font-weight: 500; font-size: 16px; line-height: 19px; color: #007AFF; cursor: pointer;}
    .top-panel .main-menu .menu-item.selected{color:#000000;background: #F5F5F5; border-radius: 4px;}
    .top-panel .main-menu .menu-item:hover {background-color: #F5F5F5; }
    .top-panel .main-menu .menu-item:first-child {border-top: 0rem none; border-top-left-radius: 0.5rem; border-top-right-radius: 0.5rem; }
    .top-panel .main-menu .menu-item:last-child {border-bottom-left-radius: 0.5rem; border-bottom-right-radius: 0.5rem; }
    .top-panel .main-menu .menu-item .icon{display: block;}

    .top-panel .dropdown.notifications{margin-left: 0rem; margin-right: 1.6rem;}
    .top-panel .dropdown.notifications > .text{display: none;}
    .top-panel .dropdown.account .circle.status{display: none;}
    .top-panel .dropdown.account .text .surname{display: none;}
    
    .conversation-page .filter-panel{ display: none; flex-direction: column; justify-content: center; align-items: flex-start; height: auto; align-items: stretch; padding: 0rem;}
    .conversation-page .filter-panel.show-mobile{ display: flex;}
    .conversation-page .filter-panel .menu{margin: 1.4rem 0rem; padding: 0rem 1.6rem 1.4rem;box-shadow: 0px 1px 0px #F5F5F5;}
    .conversation-page .filter-panel .menu .menu-item.search-open{position: absolute; top: 0rem; left: 1rem; right: 1rem; height: 3.8rem; padding: 0rem;}
    .conversation-page .filter-panel .menu .menu-item.search-open .search-input{position: absolute; left: 0rem; right: 0rem; width: auto;}
    .conversation-page .filter-panel .filters{padding: 0rem 1.6rem;}
    .conversation-page .filter-panel .filters-wrapper { margin-left: 0rem; height: 4.6rem;}
    .conversation-page .filter-panel .filters-wrapper.dropdown-opened { height: 18rem;}
    .conversation-page .filter-panel .filters-wrapper .filters{justify-content: initial; height: auto;}
    .conversation-page .filter-panel .filters-wrapper .filters .add-filter{margin-left: auto;}
    .conversation-page .filter-panel .filters-wrapper .filters .reset{padding-right: 1.6rem;}    

    .conversation-page .mobile-menu{position:relative; display: flex; align-items: center; flex-shrink: 0; height: 8rem; padding: 0.8rem 1.4rem; border-top: 1px solid #E5E5E5;}
    .conversation-page .mobile-menu .item{position:relative; display: flex; flex-direction: column; justify-content: center; align-items: center; padding: 0.3rem; flex: 1 1 0; width: 0; cursor: pointer;}
    .conversation-page .mobile-menu .item:hover,
    .conversation-page .mobile-menu .item.selected{background: #F5F5F5; border-radius: 4px;}
    .conversation-page .mobile-menu .item .icon{font-size: 2.4rem; color:#007AFF;}    
    .conversation-page .mobile-menu .item .badge{position: absolute; transform: translate(-50%, -40%); top: 40%; left: 50%; margin-left: 1rem; display: flex; flex-direction: column; justify-content: center; align-items: center; padding: 1px 6px 2px; grid-gap: 10px; gap: 10px; width: 21px; height: 20px; background: #007AFF; border-radius: 99px; font-family: 'ABC Normal Medium'; font-style: normal; font-weight: 500; font-size: 14px; line-height: 17px; color: #FFFFFF;}   
    .conversation-page .mobile-menu .item:hover .icon,    
    .conversation-page .mobile-menu .item.selected .icon{color:#000000;}
    .conversation-page .mobile-menu .item .text{font-family: 'ABC Normal Medium'; font-style: normal; font-weight: 500; font-size: 16px; line-height: 140%; text-align: center; color: #007AFF;overflow: hidden; max-width: 100%; text-overflow: ellipsis; white-space: nowrap;}  
    .conversation-page .mobile-menu .item:hover .text,
    .conversation-page .mobile-menu .item.selected .text{color:#000000;}

    .conversation-page .left-column, .conversation-page .middle-column, .conversation-page .right-column, .conversation-page .no-conversation-selected{display: none; flex-grow: 1; width: 30rem; }
    .conversation-page .left-column.show-mobile, .conversation-page .middle-column.show-mobile, .conversation-page .right-column.show-mobile{display: flex; }
    .conversation-page .left-column .conversation-list-panel .list-content{padding-top: 0rem;}
    .conversation-page .left-column .conversation-list-panel .list-content .new-unvisible-messages-alert{ margin-top: 1.4rem; margin-bottom: 0rem;}
    .conversation-page .left-column .conversation-list-panel .list-content .conversation-item{ margin-left: 1.6rem; margin-right: 1.6rem;}

    .conversation-page .middle-column .header{flex-direction: column;}
    .conversation-page .middle-column .header .avatar-block{margin-bottom: 1.6rem;}
    .conversation-page .middle-column .header .actions{justify-content: center;}
    .conversation-page .middle-column .header .actions .item.toggle-right-panel{display: none;}
    .conversation-page .middle-column .respond-panel{margin:0rem;}
    .conversation-page .middle-column .respond-panel .text-block .controls .control .text{display: none;}    
    .conversation-page .middle-column .respond-panel .text-block .shortcut-list table.titles th.shortcut{width: 16rem; max-width: 16rem; min-width: 16rem;}
    .conversation-page .middle-column .respond-panel .text-block .shortcut-list .content table td.shortcut{width: 16rem; max-width: 16rem; min-width: 16rem;}

    .conversation-page .middle-column .join-info {margin: 0rem;}

    .conversation-page .right-column {display: none}
    .conversation-page .right-column.show-mobile {display: flex; width: auto; position: relative; top:auto; right: auto; bottom: auto; left: auto; z-index: 0;}
    .conversation-page .right-column .section .note-list .note-item .controls{display: flex;}
    .conversation-page .right-column .section .task-list .task-item .controls{display: flex;}

    .modal .modal-content{padding: 2.4rem;}
    .modal,.modal.center{left: 1.6rem; right: 1.6rem; transform: translate(0%, -60%);}
    
    
}