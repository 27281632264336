.menu {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.menu .menu-item {
    display: flex;
    align-items: center;
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #007aff;
    padding: 0.4rem 1.6rem;
    text-align: center;
    cursor: pointer;
}
.menu .menu-item .icon {
    font-size: 1.6rem;
    margin-right: 0.8rem;
}
.menu .menu-item.selected {
    color: #000000;
    background: #f5f5f5;
    border-radius: 32px;
}
.menu .menu-item .badge {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1px 6px 2px;
    gap: 10px;
    width: 21px;
    height: 20px;
    background: #007aff;
    border-radius: 99px;
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
}

.tab-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0px 1px 0px #e5e5e5;
}
.tab-menu .tab-item {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-grow: 1;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    user-select: none;
}
.tab-menu .tab-item:first-child {
    margin-left: 0rem;
}
.tab-menu .tab-item:last-child {
    margin-right: 0rem;
}
.tab-menu .tab-item .title {
    font-family: "ABC Normal Medium";
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    color: #000000;
}
.tab-menu .tab-item.selected {
    box-shadow: 0px 1px 0px #007aff;
}
.tab-menu .tab-item.selected .title {
    color: #007aff;
}

.dropdown {
    position: relative;
    display: flex;
    user-select: none;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    cursor: pointer;
}

.dropdown .text {
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
}
.dropdown .text.blue {
    color: #007aff;
}
.dropdown > .icon {
    margin-left: 0.8rem;
    font-size: 14px;
}
.dropdown .addon {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.dropdown .addon .separator {
    width: 1px;
    height: 20px;
    background: #e5e5e5;
    margin: 0rem 0.8rem;
}
.dropdown .addon .icon {
    color: #000000;
    font-size: 1.4rem;
}
.dropdown .text .icon {
    font-size: 2rem;
    margin-left: 0.8rem;
    margin-right: 1rem;
}

.dropdown .circle {
    width: 3rem;
    height: 3rem;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.8rem;
}
.dropdown .circle.blue {
    background-color: #007aff;
}
.dropdown .circle.online {
    background-color: #7ac942;
}
.dropdown .circle > .icon {
    font-size: 2.4rem;
    color: #ffffff;
}
.dropdown .circle.offline {
    background-color: #ff0000;
}
.dropdown .circle.paused {
    background-color: #ff9900;
}
.dropdown .circle .text {
    color: #ffffff;
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.7rem;
    text-align: center;
}
.dropdown .dropdown-content {
    display: none;
    min-width: 100%;
    flex-direction: column;
    position: absolute;
    z-index: 100;
    top: 4rem;
    left: 0rem;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    padding: 1.6rem;
    border-radius: 8px;
    cursor: default;
}

.dropdown .dropdown-content .arrow {
    width: 1rem;
    height: 1rem;
    transform: rotate(45deg);
    position: absolute;
    z-index: 100;
    background-color: #ffffff;
    top: -0.5rem;
}
.dropdown .dropdown-content.show {
    display: flex;
}
.dropdown .dropdown-content .dropdown-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.6rem 1.6rem;
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #007aff;
    cursor: pointer;
    border-radius: 0.5rem;
}
.dropdown .dropdown-content .dropdown-item.separator {
    background: var(#e5e5e5);
}
.dropdown .dropdown-content .dropdown-item a {
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
}
.dropdown .dropdown-content .dropdown-item:hover {
    background-color: #f5f5f5;
}

.dropdown.small .text {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 3rem;
}

.dropdown.filled {
    background: #f5f5f5;
    border-radius: 4px;
    min-height: 38px;
    padding: 0.4rem 0.8rem;
}
.dropdown.filled:hover {
    background: #e5e5e5;
}

.dropdown.filled.rounded {
    border-radius: 38px;
}
.dropdown.filled > .text {
    flex-grow: 1;
    text-align: left;
    white-space: nowrap;
}
.dropdown.filled.small {
    border-radius: 4px;
    min-height: 32px;
    padding: 0rem 1.6rem;
}
.dropdown.filled.rounded.small {
    border-radius: 16px;
}

.filled-block {
    padding: 0rem 0.8rem;
    position: relative;
    display: flex;
    user-select: none;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    background-color: #f5f5f5;
    padding: 0rem 0.8rem;
    border-radius: 0.8rem;
}
.filled-block.grey {
    background-color: #f5f5f5;
}
.filled-block .text {
    margin: 0rem 0.8rem;
    font-family: "ABC Normal";
    color: #000000;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.9rem;
    text-align: center;
    margin-right: 0.8rem;
}
.filled-block.small {
    border-radius: 0.4rem;
}
.filled-block.small .text {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 3rem;
}

.avatar {
    border-radius: 100px;
    width: 3rem;
    height: 3rem;
    position: relative;
}
.avatar .status {
    position: absolute;
    bottom: -0.3rem;
    right: -0.2rem;
    z-index: 100;
}
.avatar .avatar-content {
    border-radius: 100px;
    width: inherit;
    height: inherit;
    position: relative;
    overflow: hidden;
}
.avatar .avatar-content img {
    width: 100%;
    height: 100%;
}
.avatar .avatar-content .colored-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.avatar .avatar-content .colored-avatar .text {
    color: #fff;
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.7rem;
}

.avatar.small {
    width: 2rem;
    height: 2rem;
    overflow: hidden;
}
.avatar.small .avatar-content .colored-avatar .text {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.3rem;
}

.form-group {
    display: flex;
    flex-direction: column;
}
.form-group label {
    display: flex;
    font-family: "ABC Normal Neutral";
    font-size: 16px;
    line-height: 16px;
    color: #808080;
    margin-bottom: 0.6rem;
}
.form-group label .description {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 16px;
    margin-left: 1rem;
}
.form-group label .description.right {
    margin-left: auto;
}
.form-group .info {
    font-family: "ABC Normal Neutral";
    font-size: 12px;
    line-height: 16px;
    color: #808080;
    margin-top: 0.6rem;
}

.input-group {
    position: relative;
    display: flex;
    flex-direction: row;
    min-height: 3.8rem;
}
.input-group .addon {
    position: absolute;
    top: 0rem;
    bottom: 0rem;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
}
.input-group .addon.left {
    left: 0.8rem;
}
.input-group .addon.right {
    right: 0.8rem;
}
.input-group .addon .icon {
    font-size: 2rem;
    margin-left: 0.4rem;
}
.input-group input,
.input-group select,
.input-group textarea {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    border: 1px solid #f5f5f5;
    border-radius: 0.4rem;
    background: #f5f5f5;
    outline: none;
    height: inherit;
    box-sizing: border-box;
    padding: 0.6rem 1.6rem;
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #080808;
}
.input-group input.invalid,
.input-group select.invalid,
.input-group textarea.invalid {
    border-color: #ff0000;
}
.input-group textarea {
    position: relative;
    width: 100%;
}
.input-group input:focus,
.input-group input:focus-visible,
.input-group select:focus,
.input-group select:focus-visible {
    background: #ffffff;
    border: 1px solid #808080;
}
.input-group.has-addon-left input,
.input-group.has-addon-left select {
    padding-left: 4rem;
}
.input-group.has-addon-right input,
.input-group.has-addon-right select {
    padding-right: 4rem;
}
.conversation-page .middle-column .header .avatar-block .conversation-state .state-content {
    display: flex;
    align-items: center;
    padding: 0.9rem 1.6rem;
    border-radius: 1.6rem;
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.4rem;
}

.state-content {
    display: flex;
    align-items: center;
    padding: 0.9rem 1.6rem;
    border-radius: 1.6rem;
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.4rem;
}
.state-content .icon {
    margin-left: 0.8rem;
    font-size: 14px;
    color: #000000;
}
.state-content.active {
    background-color: #d9ebff;
}
.state-content.deffered {
    background-color: #ffebcc;
}
.state-content.solved {
    background-color: #e4f4d9;
}

.textarea {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    border: 1px solid #f5f5f5;
    border-radius: 0.4rem;
    background: #f5f5f5;
    outline: none;
    height: inherit;
    box-sizing: border-box;
    padding: 0.6rem 1.6rem;
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #080808;
}
.textarea:focus,
textarea:focus-visible {
    background: #ffffff;
    border: 1px solid #808080;
}

.btn {
    border-radius: 20px;
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    border: none;
    padding: 0.5rem 1.6rem;
    cursor: pointer;
    position: relative;
}
.btn:disabled,
.btn[disabled] {
    background-color: #cccccc;
    color: #666666;
    cursor: default;
}
.btn.small {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    padding: 0rem 2rem;
}
.btn.blue {
    background: #007aff;
    color: #ffffff;
}
.btn:disabled,
.btn[disabled],
.btn.disabled {
    background-color: #ddd;
}

.btn .loading {
    height: 3rem;
    align-self: center;
    position: absolute;
    color: blue;
    right: -3.8rem;
}

.checkbox-block {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    cursor: pointer;
}
.checkbox-block .checkbox {
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    user-select: none;
    width: 2rem;
    height: 2rem;
    background: #f5f5f5;
    border: 1px solid #a8a8a8;
    box-sizing: border-box;
    border-radius: 0.2rem;
    margin-top: 0.2rem;
}
.checkbox-block.disabled .checkbox {
    background: #e5e5e5;
    border: 1px solid #a8a8a8;
}
.checkbox-block .checkbox.checked {
    background: #007aff;
    border: 1px solid #007aff;
}
.checkbox-block.disabled .checkbox.checked {
    background: #a8a8a8;
    border: 1px solid #a8a8a8;
}
.checkbox-block .checkbox .icon {
    width: 1rem;
    color: #ffffff;
}
.checkbox-block .label {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    caret-color: transparent;
    margin-left: 1rem;
}
.checkbox-block .label.line-through {
    text-decoration-line: line-through;
}

.emote-icon-picker {
    display: flex;
    cursor: default;
    flex-direction: row;
    width: 23.8rem;
    flex-wrap: wrap;
    overflow-y: auto;
    height: 30rem;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);
    padding: 1rem;
}
.emote-icon-picker .emote-icon {
    display: flex;
    font-size: 2rem;
    margin: 0.3rem;
    cursor: pointer;
}

.modal {
    margin: 0rem auto;
    max-width: 50rem;
    padding: 2rem 0rem;
    outline: none;
}
.modal .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 4rem;
    background: #ffffff;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}
.modal .modal-content .modal-header {
    display: flex;
    position: relative;
}
.modal .modal-content .modal-header .modal-title {
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
    margin: 0rem 0rem 1.6rem 0rem;
}
.modal .modal-content .close {
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;
    display: flex;
    background: none;
    border: none;
    padding: 0rem;
    color: #000000;
    padding: 1rem;
    cursor: pointer;
    z-index: 10;
}
.modal .modal-content .close .icon {
    font-size: 1.6rem;
}
.modal .modal-content .modal-body {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
}
.modal .modal-content .modal-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4rem;
}
.modal .modal-content .modal-footer.center {
    justify-content: center;
}

.radio-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}
.radio-item.inline {
    margin-right: 1.6rem;
}
.radio-item .radio-circle {
    position: relative;
    width: 2rem;
    height: 2rem;
    border-radius: 99%;
    margin-right: 0.8rem;
    flex-shrink: 0;
    background: #f5f5f5;
    border: 1px solid #a8a8a8;
    margin: 0.4rem;
}
.radio-item.disabled .radio-circle {
    background: #e5e5e5;
    border: 1px solid #a8a8a8;
}
.radio-item .radio-circle.selected {
    background-color: #007aff;
    border-color: #007aff;
}
.radio-item.disabled .radio-circle.selected {
    background: #a8a8a8;
    border: 1px solid #a8a8a8;
}
.radio-item .radio-circle.selected::after {
    content: "";
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
    width: 0.8rem;
    height: 0.8rem;
    z-index: 100;
    border-radius: 99%;
    background-color: #ffffff;
}
.radio-item .radio-circle.selected.online {
    background-color: #7ac942;
}
.radio-item .radio-circle.selected.paused {
    background-color: #ff9900;
}
.radio-item .radio-circle.selected.offline {
    background-color: #ff0000;
}
.radio-item .radio-item-text {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    margin-left: 0.6rem;
    white-space: nowrap;
}

.link {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: inherit;
    line-height: 170%;
    text-decoration-line: underline;
    color: #007aff;
    cursor: pointer;
}

table {
    border-collapse: collapse;
}
table.full-width {
    width: 100%;
}
table thead tr th,
table tbody tr td {
    padding: 1.4rem 1.6rem;
    text-align: center;
}
table tr th.left,
table tr td.left {
    text-align: left;
}
table tr th.right,
table tr td.right {
    text-align: right;
}
table tr th.center,
table tr td.center {
    text-align: center;
}
table thead tr th {
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
}
table tbody tr td {
    border-bottom: 0.1rem solid #e5e5e5;
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
}
table tbody tr:first-child td {
    border-top: 0.1rem solid #e5e5e5;
}

table.tr-pointer tbody tr {
    cursor: pointer;
}
table.hoverable tbody tr:hover td {
    background-color: #f5f5f5;
}

.lightbox {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(245, 245, 245, 0.75);
}
.lightbox .lightbox-box {
    position: absolute;
    top: 3.2rem;
    right: 3.2rem;
    bottom: 3.2rem;
    left: 3.2rem;
    background: #ffffff;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    display: flex;
    align-items: stretch;
}
.lightbox .lightbox-box > .lightbox-content {
    padding: 4rem 4rem 6.4rem 4rem;
    width: 100%;
    height: 100%;
    text-align: center;
}
.lightbox .lightbox-box > .lightbox-content .lightbox-img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
}
.lightbox .lightbox-box > .close {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    background: #007aff;
    border: 4px solid #ffffff;
    border-radius: 2.4rem;
    width: 3.8rem;
    height: 3.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.lightbox .lightbox-box > .close .icon {
    font-size: 1.6rem;
    color: #ffffff;
}
.lightbox .lightbox-box > .close-info {
    position: absolute;
    bottom: 2.4rem;
    left: 50%;
    transform: translateX(-50%);
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #808080;
}
.lightbox .lightbox-box > .close-info .bold {
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    color: #000000;
}

.switcher {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    background: #a8a8a8;
    border-radius: 20px;
    width: 40px;
    height: 20px;
}
.switcher.active {
    background: #007aff;
}
.switcher .circle {
    position: absolute;
    background: #ffffff;
    border-radius: 1rem;
    width: 1.6rem;
    height: 1.6rem;
    left: 55%;
    right: 5%;
    top: 10%;
    bottom: 10%;
    transition: all 0.1s linear;
}
.switcher.active .circle {
    left: 5%;
    right: 55%;
    top: 10%;
    bottom: 10%;
}

.avatar-picker {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    border-radius: 280px;
    width: 28rem;
    height: 28rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-picker .cover {
    background: #007aff;
    opacity: 0;
    position: absolute;
    top: 0rem;
    left: 0rem;
    right: 0rem;
    bottom: 0rem;
    z-index: 10;
}
.avatar-picker .cover:hover {
    opacity: 0.2;
}
.avatar-picker .spinner-loading .path {
    stroke: #007aff;
}
.avatar-picker .avatar-file-input {
    display: none;
}
.avatar-picker .photo {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0rem;
    left: 0rem;
    z-index: 8;
}
.avatar-picker .default-photo {
    font-size: 16rem;
    color: #e5e5e5;
    margin-left: auto;
    margin-right: auto;
}
.avatar-picker-modal .file-description {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
    margin-bottom: 0rem;
    margin-top: 1.2rem;
}
.avatar-picker-modal .photo-block {
    display: flex;
    justify-content: center;
    margin-top: 1.6rem;
    margin-bottom: 3.2rem;
}
.avatar-picker-modal .photo-block .cropper-block {
    position: relative;
    width: 28rem;
    height: 28rem;
}
.avatar-picker-modal .photo-block .photo {
    border-radius: 28rem;
    width: 28rem;
    height: 28rem;
    cursor: pointer;
}
.avatar-picker-modal .photo-block .default-photo {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 28rem;
    width: 28rem;
    height: 28rem;
    background-color: #f5f5f5;
    cursor: pointer;
}
.avatar-picker-modal .photo-block .icon {
    font-size: 20rem;
    color: #e5e5e5;
}

.list-pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    list-style: none;
}

.list-pagination .page-item .page-link {
    display: inline-flex;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    color: #007aff;

    text-align: center;
    font-family: "ABC Normal Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
}
.list-pagination .page-item.active .page-link {
    background: #f5f5f5;
    color: #000;
    cursor: default;
}

.alert {
    display: flex;
    align-items: flex-start;
    border-radius: 0.4rem;
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
    padding: 1.6rem;
}
.alert .icon {
    font-size: 2.4rem;
    margin-right: 0.8rem;
}
.alert.green {
    background-color: #e4f4d9;
}
.alert.green .btn {
    background-color: #7ac942;
}
.alert.green .icon {
    color: #7ac942;
}
.alert.orange {
    background-color: #ffebcc;
}
.alert.orange .btn {
    background-color: #ff9900;
}
.alert.orange .icon {
    color: #ff9900;
}
.alert.red {
    background-color: #ffcccc;
}
.alert.red .btn {
    background-color: #ff0000;
}
.alert.red .icon {
    color: #ff0000;
}
.alert .icon.black {
    color: #000000;
}

.d-none {
    display: none;
}
