* {
    box-sizing: border-box;
    font-family: "ABC Normal";
}

html,
body {
    height: 100%;
    background-color: #fff;
    font-size: 10px;
    margin: 0px;
    padding: 0px;
}

#root,
.dashboard-content {
    height: 100%;
}

a {
    text-decoration: none;
}

/* Login, forgotten pwd, new account page */
.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

/* general page elements */
h1 {
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 140%;
}
.page {
    max-width: 118rem;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 15rem;
}
.page.login-page h1 {
    max-width: 80rem;
    margin-bottom: 10rem;
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 600;
    font-size: 80px;
    line-height: 96px;
    text-align: center;
}
.page.login-page h1 .blue {
    color: #1a68ff;
}

/* panel */
.page .panel {
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}
.page .panel .logo {
    margin-bottom: 6.4rem;
}
.page .panel .form-group {
    margin-bottom: 2.2rem;
}
.page .panel .btn {
    margin-top: 1rem;
    margin-bottom: 6.4rem;
}

/* panel - login, forgotten pwd, new account */
.page .panel.login-panel {
    padding: 6.4rem;
    width: 50rem;
}
.page .panel.login-panel .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.page .panel.login-panel .footer .item {
    flex-grow: 1;
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.8rem;
    text-decoration-line: underline;
    color: #007aff;
}
.page .panel.login-panel .footer .item.right {
    text-align: right;
}

/* Dashboard */

.dashboard-content {
    padding-top: 6rem;
}

/* Main top panel */
.top-panel {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;
    height: 60px;
    padding: 0rem 1.5rem;
    background-color: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    z-index: 10;
}
.top-panel .logo {
    height: 3.1rem;
}
.top-panel .logo.symbols {
    display: none;
    height: 1.3rem;
}
.top-panel .mobile-menu-link {
    display: none;
    align-items: center;
    margin-right: 1.6rem;
}
.top-panel .mobile-menu-link .icon {
    font-size: 1.4rem;
}
.top-panel .mobile-menu-link .text {
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin-right: 0.8rem;
}
.top-panel .menu-wrapper {
    display: flex;
    position: absolute;
    left: 0px;
    right: 0px;
    pointer-events: none;
}
.top-panel .main-menu {
    display: flex;
    margin-left: auto;
    margin-right: auto;
}
.top-panel .main-menu .arrow {
    display: none;
}
.top-panel .main-menu .menu {
    margin-left: auto;
    margin-right: auto;
    pointer-events: all;
}
.top-panel .menu-item .icon {
    display: none;
}
.top-panel .dropdown {
    margin-right: 2.4rem;
}
.top-panel .dropdown.notifications {
    margin-left: auto;
}
.top-panel .dropdown:last-child {
    margin-right: 0rem;
}
.top-panel .dropdown.account .dropdown-content {
    width: 20rem;
    right: -0.7rem;
    left: auto;
}
.top-panel .dropdown.account .dropdown-content .arrow {
    right: 4rem;
}
.top-panel .dropdown.account .dropdown-content::after {
    left: auto;
    right: 6rem;
}
.top-panel .dropdown.account .dropdown-content .status-block {
    padding: 1.6rem;
}
.top-panel .dropdown.account .dropdown-content .status-block .title {
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}
.top-panel .dropdown.account .dropdown-content .status-block .status-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 1.6rem;
}
.top-panel .dropdown.account .dropdown-content .status-block .status-list .status-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.top-panel .dropdown.account .dropdown-content .status-block .status-list .status-item .status-circle {
    position: relative;
    width: 2rem;
    height: 2rem;
    border-radius: 99%;
    margin-right: 0.8rem;
    flex-shrink: 0;
    background: #f5f5f5;
    border: 1px solid #a8a8a8;
    border-radius: 20px;
    margin: 0.4rem;
}
.top-panel .dropdown.account .dropdown-content .status-block .status-list .status-item .status-circle.selected {
    background-color: #7ac942;
}
.top-panel .dropdown.account .dropdown-content .status-block .status-list .status-item .status-circle.selected::after {
    content: "";
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
    width: 0.8rem;
    height: 0.8rem;
    z-index: 100;
    border-radius: 99%;
    background-color: #ffffff;
}
.top-panel .dropdown.account .dropdown-content .status-block .status-list .status-item .status-circle.selected.online {
    background-color: #7ac942;
}
.top-panel .dropdown.account .dropdown-content .status-block .status-list .status-item .status-circle.selected.paused {
    background-color: #ff9900;
}
.top-panel .dropdown.account .dropdown-content .status-block .status-list .status-item .status-circle.selected.offline {
    background-color: #ff0000;
}
.top-panel .dropdown.account .dropdown-content .status-block .status-list .status-item .status-item-text {
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    margin-left: 0.8rem;
    white-space: nowrap;
}

/* stránka konverzací */
.conversation-page {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.conversation-page .filter-panel {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-start;
    background: #ffffff;
    box-shadow: 0px 1px 0px #f5f5f5;
    height: 6rem;
    padding: 0rem 3.2rem 0rem 1.6rem;
}
.conversation-page .filter-panel .menu {
    position: relative;
}
.conversation-page .filter-panel .menu .menu-item .search {
    font-size: 20px;
}
.conversation-page .filter-panel .menu .menu-item .search-input {
    width: 35.6rem;
}
.conversation-page .filter-panel .menu .menu-item.search-open .search-input .addon.left {
    font-size: 20px;
    padding-right: 1rem;
}
.conversation-page .filter-panel .menu .menu-item.search-open .search-input .addon.right {
    font-size: 20px;
    padding-left: 1rem;
}
.conversation-page .filter-panel .filters-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    height: 100%;
    flex-grow: 1;
}
.conversation-page .filter-panel .filters-wrapper .filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    justify-content: flex-end;
    height: 6rem;
}
.conversation-page .filter-panel .filters-wrapper .filters .reset {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    text-decoration-line: underline;
    color: #007aff;
    cursor: pointer;
    white-space: nowrap;
}
.conversation-page .filter-panel .filters-wrapper .filters .dropdown {
    margin-right: 1.6rem;
}
.conversation-page .filter-panel .filters-wrapper .filters .add-filter .add-filter-item .icon {
    margin-right: 0.8rem;
}

.conversation-page .conversation-columns {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    flex-grow: 1;
    position: relative;
    padding-top: 0.1rem;
}
.conversation-page .mobile-menu {
    display: none;
}

.conversation-page .left-column,
.conversation-page .middle-column,
.conversation-page .right-column,
.conversation-page .no-conversation-selected {
    height: 100%;
}
.conversation-page .left-column {
    display: flex;
    flex-direction: column;
    width: 42rem;
    box-shadow: 1px 0px 0px #f5f5f5;
    flex-shrink: 0;
    flex-grow: 0;
}
.conversation-page .left-column .conversation-list-panel {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.conversation-page .left-column .conversation-list-panel .search-input {
    margin-left: 3.2rem;
    margin-right: 3.2rem;
}
.conversation-page .left-column .conversation-list-panel i.search {
    font-size: 2rem;
    padding: 0.2rem;
}
.conversation-page .left-column .conversation-list-panel .tab-menu {
    margin-top: 3rem;
}
.conversation-page .left-column .conversation-list-panel .tab-menu .tab-item .unread-message-count {
    position: absolute;
    right: 2rem;
    top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #007aff;
    color: #fff;
    width: 2rem;
    height: 2rem;
    border-radius: 100px;
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.3rem;
    text-align: center;
}
.conversation-page .left-column .conversation-list-panel .list-content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex: 1 0 1px;
}
.conversation-page .left-column .conversation-list-panel .list-content .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #000000;
}
.conversation-page .left-column .conversation-list-panel .list-content .info .icon {
    font-size: 8rem;
    color: #e5e5e5;
    margin-bottom: 1.2rem;
}
.conversation-page .left-column .conversation-list-panel .list-content .loading-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.conversation-page .left-column .conversation-list-panel .list-content .new-unvisible-messages-alert {
    display: flex;
    align-items: center;
    background: #e4f4d9;
    border-radius: 4px;
    margin: 0rem 3.2rem 1.6rem 3.2rem;
    padding: 1.8rem 1.2rem;
    color: #000000;
}
.conversation-page .left-column .conversation-list-panel .list-content .new-unvisible-messages-alert .text {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #000000;
}
.conversation-page .left-column .conversation-list-panel .list-content .new-unvisible-messages-alert .text .reset-link {
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    text-decoration-line: underline;
    margin-right: 0.5rem;
    cursor: pointer;
}
.conversation-page .left-column .conversation-list-panel .list-content .new-unvisible-messages-alert .text .unread-count {
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    margin-right: 0.5rem;
}
.conversation-page .left-column .conversation-list-panel .list-content .new-unvisible-messages-alert .icon {
    font-size: 2rem;
    color: #000000;
    margin-right: 0.8rem;
}
.conversation-page .left-column .conversation-list-panel .list-content .conversation-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.6rem;
    margin-bottom: 0.8rem;
    border-radius: 8px;
    margin-left: 3.2rem;
    margin-right: 3.2rem;
}
.conversation-page .left-column .conversation-list-panel .list-content .conversation-item:first-child {
    margin-top: 2.4rem;
}
.conversation-page .left-column .conversation-list-panel .list-content .conversation-item:hover {
    background-color: #f5f5f5;
}
.conversation-page .left-column .conversation-list-panel .list-content .conversation-item.selected {
    background-color: #ecf5ff;
}
.conversation-page .left-column .conversation-list-panel .list-content .conversation-item .header {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
}
.conversation-page .left-column .conversation-list-panel .list-content .conversation-item .header > .avatar {
    margin-right: 0.8rem;
}
.conversation-page .left-column .conversation-list-panel .list-content .conversation-item .header .visitor-title {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}
.conversation-page .left-column .conversation-list-panel .list-content .conversation-item .header .operator-list {
    display: flex;
    flex-direction: row;
    justify-content: end;
}
.conversation-page .left-column .conversation-list-panel .list-content .conversation-item .header .operator-list .add-me-and-open {
    opacity: 1;
    transition: opacity 1s linear;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 100px;
    background-color: #e5e5e5;
}
.conversation-page .left-column .conversation-list-panel .list-content .conversation-item .header .operator-list .add-me-and-open.fade {
    opacity: 0.3;
    transition: opacity 1s linear;
}
.conversation-page .left-column .conversation-list-panel .list-content .conversation-item .header .operator-list .add-me-and-open .icon {
    font-size: 1.6rem;
    color: #000000;
}
.conversation-page .left-column .conversation-list-panel .list-content .conversation-item .header .operator-list .avatar {
    width: 3.4rem;
    height: 3.4rem;
    margin-left: -1rem;
    box-shadow: inset 0px 0px 0px 0.2rem #ffffff;
    padding: 0.2rem;
    margin-right: 0rem;
}
.conversation-page .left-column .conversation-list-panel .list-content .conversation-item .header .operator-list .avatar.plus {
    background-color: #007aff;
}
.conversation-page .left-column .conversation-list-panel .list-content .conversation-item .header .operator-list .avatar .avatar-content {
    width: 3rem;
    height: 3rem;
}
.conversation-page .left-column .conversation-list-panel .list-content .conversation-item .header .operator-list .avatar.plus .avatar-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
}

.conversation-page .left-column .conversation-list-panel .list-content .conversation-item .message {
    margin-top: 0.8rem;
    padding: 0rem 0rem 0rem 3.8rem;
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 140%;
    color: #000000;
    max-height: 3.8rem;
    overflow: hidden;
}
.conversation-page .left-column .conversation-list-panel .list-content .conversation-item .footer {
    display: flex;
    flex-direction: row;
    margin-top: 0.8rem;
    width: 100%;
    padding-left: 3.8rem;
}
.conversation-page .left-column .conversation-list-panel .list-content .conversation-item .footer .message-count {
    flex: 1 0 0%;
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    color: #000000;
}
.conversation-page .left-column .conversation-list-panel .list-content .conversation-item .footer .message-count.new {
    color: #007aff;
}
.conversation-page .left-column .conversation-list-panel .list-content .conversation-item .footer .conversation-status {
    flex: 1 0 0%;
    text-align: right;
}
.conversation-page .left-column .conversation-list-panel .list-content .conversation-item .footer .conversation-status .status-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #808080;
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
}
.conversation-page .left-column .conversation-list-panel .list-content .conversation-item .footer .conversation-status .status-content > .typing {
    color: #808080;
}
.conversation-page .left-column .conversation-list-panel .list-content .conversation-item .footer .conversation-status .status-content.orange {
    color: #ff9900;
}
.conversation-page .left-column .conversation-list-panel .list-content .conversation-item .footer .conversation-status .status-content.red {
    color: #ff0000;
}
.conversation-page .left-column .conversation-list-panel .list-content .conversation-item .footer .conversation-status .status-content.solved {
    color: #7ac942;
}
.conversation-page .left-column .conversation-list-panel .list-content .conversation-item .footer .conversation-status .status-content .icon {
    margin-left: 0.4rem;
    width: 1.339rem;
    font-size: 1.4rem;
}
.conversation-page .left-column .conversation-list-panel .list-content .conversation-item .footer .conversation-status .status-content .icon.la-pencil-alt {
    font-size: 1.8rem;
}

.conversation-page .left-column .unread-message-count-info {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.8rem;
    text-align: center;
    padding: 2rem 0rem;
}

.conversation-page .middle-column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
}
.conversation-page .middle-column .header {
    display: flex;
    flex-direction: row;
    padding: 2.4rem 3.2rem;
}
.conversation-page .middle-column .header .avatar-block {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.conversation-page .middle-column .header .avatar-block .visitor-title {
    margin-right: 1.6rem;
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
}
.conversation-page .middle-column .header .avatar-block .conversation-state {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.6rem;
}
.conversation-page .middle-column .header .avatar-block .conversation-state .dropdown-content {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
    right: 0rem;
    left: auto;
}
.conversation-page .middle-column .header .avatar-block .conversation-state .dropdown-content .title {
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 1.6rem;
}
.conversation-page .middle-column .header .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
}
.conversation-page .middle-column .header .actions .filled-block {
    margin-right: 0.8rem;
}
.conversation-page .middle-column .header .actions .dropdown {
    margin-right: 0.8rem;
}
.conversation-page .middle-column .header .actions .dropdown:last-child {
    margin-right: 0rem;
}
.conversation-page .middle-column .header .actions .filled-block .status,
.conversation-page .middle-column .header .actions .dropdown .icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 0.8rem;
}
.conversation-page .middle-column .header .actions .dropdown.options .icon img {
    width: 1.2rem;
}
.conversation-page .middle-column .header .actions .dropdown.conversation-status .icon img {
    width: 1.5rem;
}
.conversation-page .middle-column .header .actions .dropdown.options .dropdown-content {
    width: 17rem;
}
.conversation-page .middle-column .header .actions .item {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #808080;
    margin-left: 1.6rem;
    cursor: pointer;
}
.conversation-page .middle-column .header .actions .item.toggle-right-panel {
    display: none;
}
.conversation-page .middle-column .header .actions .item:hover {
    color: #000000;
}
.conversation-page .middle-column .header .actions .item:first-child {
    margin-left: 0rem;
}
.conversation-page .middle-column .header .actions .item .icon {
    font-size: 2.2rem;
    margin-right: 0.8rem;
}
.conversation-page .middle-column .header .actions .item .text {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-decoration-line: underline;
}

.conversation-page .middle-column .dropzone {
    position: absolute;
    top: 0rem;
    bottom: 0rem;
    left: 0rem;
    right: 0rem;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    background-color: rgb(245 245 245 / 75%);
    color: rgb(189, 189, 189);
    outline: none;
    transition: border 0.24s ease-in-out 0s;
    display: none;
}
.conversation-page .middle-column .dropzone.visible {
    display: flex;
}
.conversation-page .middle-column .dropzone .drop-files-here {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.conversation-page .middle-column .dropzone .drop-files-here .icon {
    font-size: 6rem;
    color: #007aff;
    margin-bottom: 2.4rem;
}
.conversation-page .middle-column .dropzone .drop-files-here .text {
    width: 30rem;
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
    color: #000000;
}

.conversation-page .middle-column .message-list {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    position: relative;
}
.conversation-page .middle-column .message-list .message-list-content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex: 1 1 1px;
    padding: 0rem 3.2rem;
}
.conversation-page .middle-column .message-list .message-list-content .message-system-info {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #808080;
    margin: 2rem 0rem;
}
.conversation-page .middle-column .message-list .message-list-content .messages-date-separator {
    position: relative;
    margin: 2.4rem 0rem;
    text-align: center;
    padding: 1rem 0rem;
}
.conversation-page .middle-column .message-list .message-list-content .messages-date-separator::before {
    content: "";
    height: 0px;
    position: absolute;
    top: 2rem;
    left: 0rem;
    right: 0rem;
    border-bottom: 1px solid #f5f5f5;
    z-index: 0;
}
.conversation-page .middle-column .message-list .message-list-content .messages-date-separator .separator-date {
    position: relative;
    display: inline-block;
    background-color: #ffffff;
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #808080;
    z-index: 2;
    padding: 0rem 1rem;
}
.conversation-page .middle-column .message-list .typing-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2rem 3.2rem;
}
.conversation-page .middle-column .message-list .typing-list .typing-icon {
    color: #808080;
    margin-right: 0.8rem;
    font-size: 1.8rem;
}
.conversation-page .middle-column .message-list .typing-list .typing-text {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #808080;
}
.conversation-page .middle-column .message-list .typing-list .avatar {
    margin-right: 0.8rem;
}

.message-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 1.2rem 0rem;
}
.message-item .message-avatar {
    margin-right: 0.8rem;
}
.message-item .message-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 9.4rem;
}
.message-item .message-content .message-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.8rem;
}
.message-item .message-content .message-header .name {
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.9rem;
    margin-right: 1rem;
}
.message-item .message-content .message-header .date-time {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 2rem;
    color: #a8a8a8;
    cursor: default;
}
.message-item .message-content .message {
    white-space: pre-line;
    padding: 0.8rem;
    background: #d9ebff;
    border-radius: 0px 8px 8px 8px;
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 140%;
    color: #000000;
}
.message-item .message-content .read-by {
    position: relative;
    display: flex;
    align-items: center;
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 2rem;
    color: #a8a8a8;
    cursor: default;
    margin-top: 0.8rem;
    cursor: pointer;
}
.message-item .message-content .read-by .read-by-icon {
    margin-left: 0.6rem;
    color: #007aff;
    font-size: 1.8rem;
}
.message-item .message-content .read-by .read-by-content {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 2rem;
    left: 0rem;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);
    padding: 1rem;
}
.message-item .message-content .read-by .read-by-content .item {
    display: flex;
    flex-direction: row;
}
.message-item .message-content .read-by .read-by-content .item .name {
    white-space: nowrap;
    min-width: 20rem;
}
.message-item .message-content .read-by .read-by-content .item .date-time {
    white-space: nowrap;
}

.message-item .message-content .rating {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 0.8rem;
    cursor: pointer;
}
.message-item .message-content .rating .rating-item {
    display: flex;
    align-items: center;
    background: #f5f5f5;
    border-radius: 4px;
    padding: 8px;
    margin-right: 0.8rem;
    margin-bottom: 0.8rem;
}
.message-item .message-content .rating .rating-item:hover {
    background-color: #e5e5e5;
}
.message-item .message-content .rating .rating-item.no-hover:hover {
    background-color: #f5f5f5;
    cursor: default;
}
.message-item .message-content .rating .rating-item .icon {
    font-size: 2rem;
    margin-right: 0.8rem;
}
.message-item .message-content .rating .rating-item .text {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
}

.message-item .message-content .message .files {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    justify-content: flex-start;
}
.message-item .message-content .message .files .file-item {
    height: 12rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    margin-bottom: 1.6rem;
}
.message-item .message-content .message .files .file-item img {
    height: 100%;
    width: auto;
    border-radius: 0.4rem;
}
.message-item .message-content .message .files .file-item .file {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 12rem;
    width: 18rem;
    background-color: #ffffff;
    border-radius: 0.4rem;
}
.message-item .message-content .message .files .file-item .file .icon {
    font-size: 4rem;
    color: #007aff;
    margin-bottom: 0.8rem;
}
.message-item .message-content .message .files .file-item .file .name {
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #007aff;
}

.message-item.grouped {
    margin-bottom: 0.8rem;
    margin-top: 0rem;
}
.message-item.grouped .message-avatar {
    display: none;
}
.message-item.grouped .message-content {
    margin-left: 4rem;
    min-height: auto;
}
.message-item.grouped .message-content .message-header {
    display: none;
}

.message-item.grouped.grouped-first {
    margin-top: 1.2rem;
}
.message-item.grouped.grouped-first .message-avatar {
    display: block;
}
.message-item.grouped.grouped-first .message-content {
    margin-left: 0rem;
}
.message-item.grouped.grouped-first .message-content .message-header {
    display: flex;
}

.message-item.grouped.grouped-last {
    margin-bottom: 1.2rem;
}

.message-item.sent {
    flex-direction: row-reverse;
}
.message-item.sent .message-avatar {
    margin-right: 0rem;
    margin-left: 0.8rem;
}
.message-item.sent .message-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.message-item.sent .message-content .message-header {
    flex-direction: row-reverse;
    justify-content: flex-end;
}
.message-item.sent .message-content .message-header .name {
    margin-right: 0rem;
    margin-left: 1rem;
}
.message-item.sent .message-content .message {
    background-color: #f5f5f5;
    border-radius: 8px 0px 8px 8px;
    color: #000000;
}
.message-item.sent .message-content .message .files {
    justify-content: flex-end;
}
.message-item.sent .message-content .read-by .read-by-content {
    left: auto;
    right: 0rem;
}

.message-item.sent.grouped .message-content {
    margin-left: 0rem;
    margin-right: 4rem;
}
.message-item.sent.grouped .message-content .read-by {
    display: none;
}
.message-item.sent.grouped.grouped-last .message-content .read-by {
    display: flex;
}
.message-item.sent.grouped.grouped-first .message-content {
    margin-right: 0rem;
}

.message-item.typing {
    margin: 0rem 2rem 0rem 0rem;
}
.message-item.typing .message-content {
    min-height: 0rem;
}
.message-item.typing .message-content .message-header {
    margin-bottom: 0.4rem;
}
.message-item.typing .message-content .message-header .name {
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin-right: 1rem;
}
.message-item.typing .message-content .message {
    display: flex;
    padding: 0.4rem 0.8rem;
    background: #e4f4d9;
    font-size: 1rem;
    align-items: center;
}
.message-item.typing .message-content .message .typing-icon {
    margin-right: 1rem;
    width: 1.4rem;
}

.conversation-page .middle-column .respond-panel {
    display: flex;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: 0rem 3.2rem 3.2rem;
    user-select: none;
}
.conversation-page .middle-column .respond-panel .text-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    user-select: none;
    padding: 2.4rem 0rem;
}
.conversation-page .middle-column .respond-panel .text-block .textarea-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    user-select: none;
    padding: 0rem 2.4rem;
}
.conversation-page .middle-column .respond-panel .text-block .shortcut-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 2.4rem;
    padding: 0rem 1.2rem;
}
.conversation-page .middle-column .respond-panel .text-block .shortcut-list .scroll-corrector {
    padding: 0rem 1.2rem;
}
.conversation-page .middle-column .respond-panel .text-block .shortcut-list table.titles th.shortcut {
    width: 25rem;
    overflow: hidden;
    white-space: nowrap;
    max-width: 25rem;
    min-width: 25rem;
    text-overflow: ellipsis;
}
.conversation-page .middle-column .respond-panel .text-block .shortcut-list table.titles th.message {
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.conversation-page .middle-column .respond-panel .text-block .shortcut-list .content {
    height: 20rem;
}
.conversation-page .middle-column .respond-panel .text-block .shortcut-list .content table td.shortcut {
    width: 25rem;
    overflow: hidden;
    white-space: nowrap;
    max-width: 25rem;
    min-width: 25rem;
    text-overflow: ellipsis;
}
.conversation-page .middle-column .respond-panel .text-block .shortcut-list .content table td.message {
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.conversation-page .middle-column .respond-panel .text-block textarea {
    border: none;
    resize: none;
    outline: none;
    flex-grow: 1;
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.8rem;
    user-select: auto;
}
.conversation-page .middle-column .respond-panel .text-block textarea::placeholder {
    color: #a8a8a8;
}
.conversation-page .middle-column .respond-panel .text-block .files {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin: 1rem 0rem;
}
.conversation-page .middle-column .respond-panel .text-block .files .file {
    position: relative;
    display: flex;
    align-items: center;
    height: 3.8rem;
    background-color: #f5f5f5;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    margin: 0rem 0.8rem 0.8rem 0rem;
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
}
.conversation-page .middle-column .respond-panel .text-block .files .file:hover {
    background-color: #e5e5e5;
}
.conversation-page .middle-column .respond-panel .text-block .files .file .icon-wrapper {
    position: relative;
}
.conversation-page .middle-column .respond-panel .text-block .files .file .icon-wrapper .preview {
    display: none;
    position: absolute;
    bottom: 4.6rem;
    left: -1rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 0.4rem;
}
.conversation-page .middle-column .respond-panel .text-block .files .file .icon-wrapper:hover .preview {
    display: block;
}
.conversation-page .middle-column .respond-panel .text-block .files .file .icon-wrapper .preview img {
    width: 20rem;
}
.conversation-page .middle-column .respond-panel .text-block .files .file .icon-wrapper .icon {
    margin-right: 0.5em;
    color: #007aff;
    font-size: 2rem;
}
.conversation-page .middle-column .respond-panel .text-block .files .file .loading {
    margin-right: 0.5em !important;
    color: #007aff;
    width: 2rem;
    height: 2rem;
}
.conversation-page .middle-column .respond-panel .text-block .files .file .delete {
    color: #a8a8a8;
    margin-left: 1em;
    cursor: pointer;
    font-size: 2rem;
}
.conversation-page .middle-column .respond-panel .text-block .files .file .delete:hover {
    color: #000000;
}
.conversation-page .middle-column .respond-panel .text-block .controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}
.conversation-page .middle-column .respond-panel .text-block .controls .control {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #808080;
    margin-right: 1.6rem;
    cursor: pointer;
}
.conversation-page .middle-column .respond-panel .text-block .controls .control:hover {
    color: #000000;
}
.conversation-page .middle-column .respond-panel .text-block .controls .control .icon {
    height: 2rem;
    margin-right: 1rem;
    font-size: 2.2rem;
    text-decoration: none;
}
.conversation-page .middle-column .respond-panel .text-block .controls .control .text {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.8rem;
    text-decoration-line: underline;
}
.conversation-page .middle-column .respond-panel .text-block .controls .control .emoteicon-control {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.conversation-page .middle-column .respond-panel .text-block .controls .control .emote-icon-picker {
    position: absolute;
    bottom: 3rem;
    left: 0rem;
}
.conversation-page .middle-column .respond-panel .text-block .controls .btn {
    padding-left: 5rem;
    padding-right: 5rem;
}

.conversation-page .middle-column .join-info {
    display: flex;
    background: #ffffff;
    margin: 0rem 3.2rem 3.2rem;
    height: 15.2rem;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
.conversation-page .middle-column .join-info .join-info-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0rem 2rem;
}
.conversation-page .middle-column .join-info .join-info-content p {
    font-family: "ABC Normal";
    font-weight: 400;
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1.6rem;
    line-height: 160%;
}
.conversation-page .middle-column .join-info .join-info-content button {
    margin-bottom: 2rem;
}

.conversation-page .right-column {
    display: flex;
    width: 42rem;
    box-shadow: -1px 0px 0px #f5f5f5;
    flex-shrink: 0;
    flex-grow: 0;
    background-color: #ffffff;
    transition: 0.5s all;
}
.conversation-page .right-column .close-column {
    display: none;
}
.conversation-page .right-column .right-column-content {
    display: flex;
    flex-direction: column;
    padding: 3.2rem;
}
.conversation-page .right-column .section {
    display: flex;
    flex-direction: column;
    margin-bottom: 3.2rem;
}
.conversation-page .right-column .section .title {
    display: flex;
    align-items: center;
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 130%;
    margin-bottom: 1.6rem;
}
.conversation-page .right-column .section .title .section-status {
    font-family: "ABC Normal";
    font-weight: 400;
    margin-left: 0.5rem;
}
.conversation-page .right-column .section .title .expandable-control {
    margin-left: auto;
    cursor: pointer;
}
.conversation-page .right-column .section .title .expandable-control .icon {
    font-size: 2.4rem;
    color: #007aff;
}

.conversation-page .right-column .section .operator-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.conversation-page .right-column .section .operator-list .item {
    margin-right: 1.2rem;
    cursor: default;
    margin-bottom: 1.2rem;
}

.conversation-page .right-column .section .task-list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 1.6rem;
}
.conversation-page .right-column .section .task-list .task-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.8rem;
    min-height: 2.4rem;
}
.conversation-page .right-column .section .task-list .task-item:first-child {
    margin-top: 0rem;
}
.conversation-page .right-column .section .task-list .task-item .controls {
    display: none;
}
.conversation-page .right-column .section .task-list .task-item:hover .controls {
    display: flex;
}
.conversation-page .right-column .section .task-list .task-item .controls .icon {
    color: #007aff;
    margin-left: 0.8rem;
    cursor: pointer;
    font-size: 2.4rem;
}
.conversation-page .right-column .section .task-list .task-item .controls .icon:first-child {
    margin-left: 0rem;
}
.conversation-page .right-column .section .task-list .task-item .add-edit-block {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.conversation-page .right-column .section .task-list .task-item .add-edit-block .input-group {
    height: auto;
    min-height: 3.8rem;
}
.conversation-page .right-column .section .task-list .task-item .add-edit-block .input-group .textarea {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    width: 100%;
    resize: none;
}
.conversation-page .right-column .section .task-list .task-item .add-edit-block .save {
    display: flex;
    flex-direction: row;
    margin-top: 0.8rem;
    align-items: center;
}
.conversation-page .right-column .section .task-list .task-item .add-edit-block .save .link {
    margin-left: 0.8rem;
}
.conversation-page .right-column .section .task-list .no-items {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
}
.conversation-page .right-column .section .control-link {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.8rem;
    text-decoration-line: underline;
    color: #007aff;
    cursor: pointer;
    caret-color: transparent;
}

.conversation-page .right-column .section .note-list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 1.6rem;
}
.conversation-page .right-column .section .note-list .note-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.2rem;
    min-height: 2.4rem;
}
.conversation-page .right-column .section .note-list .note-item .note-content {
    display: flex;
    align-items: flex-start;
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 140%;
    cursor: default;
}
.conversation-page .right-column .section .note-list .note-item:last-child {
    margin-bottom: 0rem;
}
.conversation-page .right-column .section .note-list .note-item .note-content > .icon {
    flex-shrink: 0;
    color: #ff9900;
    margin-right: 0.8rem;
    font-size: 2.2rem;
}
.conversation-page .right-column .section .note-list .note-item .controls {
    display: none;
}
.conversation-page .right-column .section .note-list .note-item:hover .controls {
    display: flex;
}
.conversation-page .right-column .section .note-list .note-item .controls .icon {
    color: #007aff;
    margin-left: 0.8rem;
    cursor: pointer;
    font-size: 2.4rem;
}
.conversation-page .right-column .section .note-list .note-item .controls .icon:first-child {
    margin-left: 0rem;
}
.conversation-page .right-column .section .note-list .note-item .add-edit-block {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.conversation-page .right-column .section .note-list .note-item .add-edit-block .input-group {
    height: auto;
    min-height: 3.8rem;
}
.conversation-page .right-column .section .note-list .note-item .add-edit-block .input-group .textarea {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    width: 100%;
    resize: none;
}
.conversation-page .right-column .section .note-list .note-item .add-edit-block .save {
    display: flex;
    flex-direction: row;
    margin-top: 0.8rem;
    align-items: center;
}
.conversation-page .right-column .section .note-list .note-item .add-edit-block .save .link {
    margin-left: 0.8rem;
}
.conversation-page .right-column .section .note-list .no-items {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
}

.conversation-page .right-column .section .contact-item-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1.6rem;
}
.conversation-page .right-column .section .contact-item-list .item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 0.8rem;
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.8rem;
    max-width: 100%;
}
.conversation-page .right-column .section .contact-item-list .item .contact-title {
    color: #808080;
}
.conversation-page .right-column .section .contact-item-list .item .contact-text {
    flex-grow: 0;
}
.conversation-page .right-column .section .contact-item-list .item .icon-wrapper {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 2.4rem;
    height: 2.4rem;
    margin-top: 0.2rem;
    margin-right: 1.6rem;
}
.conversation-page .right-column .section .contact-item-list .item .icon-wrapper .icon {
    flex-shrink: 0;
    font-size: 2.2rem;
    color: #808080;
}
.conversation-page .right-column .section .contact-item-list .item .title-block {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.conversation-page .right-column .section .contact-item-list .item .page {
    display: flex;
    flex-direction: column;
    min-width: 0;
}
.conversation-page .right-column .section .contact-item-list .item .page .page-title {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.8rem;
    text-decoration-line: underline;
    color: #000000;
    max-width: 100%;
}
.conversation-page .right-column .section .contact-item-list .item .page .page-url {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.8rem;
    color: #808080;
    max-width: 100%;
}

.conversation-page .right-column .section .status-list {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.conversation-page .no-conversation-selected {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}
.conversation-page .no-conversation-selected .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 140%;
}
.conversation-page .no-conversation-selected .info .icon {
    font-size: 8rem;
    color: #e5e5e5;
    margin-bottom: 3.2rem;
}

/* stránka nastavení */
.settings-page .page {
    margin-top: 8rem;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
}
.settings-page > .menu {
    padding: 1.4rem 3.2rem;
    background: #ffffff;
    box-shadow: 0px 1px 0px #f5f5f5;
}
.settings-page .page > .menu {
    padding: 1.4rem 0rem;
    background: #ffffff;
    box-shadow: 0px 1px 0px #f5f5f5;
    margin-bottom: 4rem;
}
.settings-page .page .settings-title {
    display: flex;
    align-items: center;
    flex-direction: row;
}
.settings-page h1 {
    margin: 0rem 0rem 2.4rem 0rem;
}
.settings-page .page .no-chatbox,
.settings-page .page .no-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 6rem;
}
.settings-page .page .no-chatbox .icon,
.settings-page .page .no-items .icon {
    font-size: 80px;
    color: #e5e5e5;
    margin-bottom: 3.2rem;
}
.settings-page .page .no-chatbox .title,
.settings-page .page .no-items .title {
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 140%;
    text-align: center;
    margin-bottom: 3.2rem;
}
.settings-page .page .no-chatbox .btn,
.settings-page .page .no-items .btn {
    padding-left: 4rem;
    padding-right: 4rem;
}
.settings-page .page .no-chatbox .info,
.settings-page .page .no-items .info {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    margin-top: 2.4rem;
    max-width: 38rem;
}
.settings-page .page table tr .chatbox-color {
    font-size: 2rem;
    margin-right: 0.8rem;
}
.settings-page .page table tr .controlls {
    display: flex;
    align-items: center;
}
.settings-page .page table tr .controlls .hidden-content {
    visibility: hidden;
    display: flex;
    align-items: center;
}
.settings-page .page table tr:hover .controlls .hidden-content {
    visibility: visible;
}
.settings-page .page table tr .controlls .hidden-content .icon {
    margin-left: 1.6rem;
    color: #006be0;
    font-size: 2.4rem;
    cursor: pointer;
}
.settings-page .page table tr .controlls .hidden-content .icon:first-child {
    margin-left: 3.2rem;
}
.settings-page .page .list-controls {
    display: flex;
    align-items: center;
    margin-top: 3.2rem;
}
.settings-page .page .btn {
    padding-left: 4rem;
    padding-right: 4rem;
}
.settings-page .page .form-group {
    margin-bottom: 2.4rem;
}
.settings-page .page .form-group .input-avatar {
    display: flex;
}
.settings-page .page .form-group .input-avatar .input-group {
    flex-grow: 1;
    margin-right: 1.6rem;
}
.settings-page .page .form-group .input-avatar .avatar-picker {
    width: 4rem;
    height: 4rem;
}
.settings-page .page .form-group .input-avatar .avatar-picker .default-photo {
    font-size: 2.5rem;
}
.settings-page .page .input-group .icon {
    font-size: 2rem;
}
.settings-page .page .back-to-list {
    font-size: 1.6rem;
    margin-left: auto;
}
.settings-page .page .back-to-list .icon {
    font-size: 2.2rem;
    color: #007aff;
    margin-right: 0.8rem;
}

.settings-page .page .appearance {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.settings-page .page .appearance .left-column {
    width: 38rem;
    margin-right: 6.2rem;
}
.settings-page .page .appearance .left-column .btn {
    margin-top: 4rem;
    display: block;
}
.settings-page .page .appearance .left-column .input-group select.language option {
    padding-left: 2rem;
}
.settings-page .page .appearance .right-column {
    position: relative;
}

.settings-page .page .group-title {
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
    margin-bottom: 1.6rem;
    margin-top: 4rem;
}
.settings-page .page .group-title:first-child {
    margin-top: 0rem;
}

.settings-page .page .linking-to-web {
    display: block;
}
.settings-page .page .linking-to-web .activation-code-desc {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
    margin-bottom: 2.4rem;
}
.settings-page .page .linking-to-web .script-title {
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    margin-top: 2.4rem;
    margin-bottom: 1.6rem;
}
.settings-page .page .linking-to-web .script {
    white-space: pre-wrap;
    overflow-wrap: break-word;
    background: #f5f5f5;
    border: 1px solid #f5f5f5;
    border-radius: 4px;
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    padding: 0.7rem 1.6rem;
    margin-bottom: 4rem;
}

.settings-page .page .introduction {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.settings-page .page .introduction .left-column {
    width: 58rem;
    margin-right: 6.2rem;
}
.settings-page .page .introduction .left-column .btn {
    margin-top: 4rem;
    display: block;
}
.settings-page .page .introduction .left-column .required-fields-header {
    display: flex;
    flex-direction: row;
}
.settings-page .page .introduction .left-column .required-fields-header .left {
    flex-grow: 1;
}
.settings-page .page .introduction .left-column .required-fields-header .right {
    display: flex;
    justify-content: center;
}
.settings-page .page .introduction .left-column .introduction-table {
    margin-bottom: 4rem;
}
.settings-page .page .introduction .left-column .introduction-table th,
.settings-page .page .introduction .left-column .introduction-table td {
    padding-left: 0rem;
    padding-right: 0rem;
}
.settings-page .page .introduction .left-column .gdpr-checkbox {
    margin-bottom: 1rem;
}

.settings-page .page .introduction .right-column {
    position: relative;
}

.settings-page .page .rating {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.settings-page .page .rating .left-column {
    width: 58rem;
    margin-right: 6.2rem;
}

.settings-page .page .account .content .btn {
    margin-top: 4rem;
    display: block;
}
.settings-page .page .account.general select {
    width: 38rem;
}

.settings-page .page .account.security .add-blockedIP-ip-info {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 2.4rem;
}
.settings-page .page .account.security table .ip {
    min-width: 61rem;
    max-width: 61rem;
}

.settings-page .page .account.operator-count .shadow-box {
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 8rem;
}
.settings-page .page .account.operator-count .shadow-box .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 3.2rem;
}
.settings-page .page .account.operator-count .shadow-box .title .icon {
    font-size: 24px;
    margin-right: 0.8rem;
}
.settings-page .page .account.operator-count .shadow-box .title .text {
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
}
.settings-page .page .account.operator-count .shadow-box .price-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 4.8rem;
}
.settings-page .page .account.operator-count .shadow-box .price-block .btn {
    margin-top: 0rem;
}
.settings-page .page .account.operator-count .shadow-box .price-block .btn .icon {
    font-size: 18px;
}
.settings-page .page .account.operator-count .shadow-box .price-block .count-number {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    margin-left: 3.2rem;
    margin-right: 3.2rem;
}
.settings-page .page .account.operator-count .shadow-box .price-block .price {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 48px;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
}
.settings-page .page .account.operator-count .shadow-box .info-block {
    display: flex;
    flex-direction: column;
}
.settings-page .page .account.operator-count .shadow-box .info-block .info {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
}

.settings-page .page .edit-operator {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.settings-page .page .edit-operator .left-column {
    width: 58rem;
    margin-right: 6.2rem;
}
.settings-page .page .edit-operator .left-column .remove-account-paragraph {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    margin-top: 4rem;
}
.settings-page .page .edit-operator .right-column {
    position: relative;
}

/* stránka lidé */
.people-page .page {
    margin-top: 8rem;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
}
.people-page > .menu {
    padding: 1.4rem 3.2rem;
    background: #ffffff;
    box-shadow: 0px 1px 0px #f5f5f5;
}
.people-page .page > .menu {
    padding: 1.4rem 0rem;
    background: #ffffff;
    box-shadow: 0px 1px 0px #f5f5f5;
    margin-bottom: 4rem;
}
.people-page .page .people-title {
    display: flex;
    align-items: center;
    flex-direction: row;
}
.people-page .page .people-title .visitor-list-count {
    margin-left: auto;
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 140%;
    color: #007aff;
}
.people-page h1 {
    margin: 0rem 0rem 2.4rem 0rem;
}
.people-page .page .controls {
    display: flex;
    align-items: center;
    margin-bottom: 2.4rem;
}
.people-page .page .controls .form-group.search {
    width: 38rem;
}
.people-page .page .controls .form-group.sort {
    margin-left: auto;
}
.people-page .page .controls .form-group.sort .dropdown {
    width: 28rem;
}
.people-page .page .controls .form-group.sort .dropdown .text .icon {
    font-size: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
}
.people-page .page .no-visitor {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 8rem;
}
.people-page .page .no-visitor .icon {
    color: #e5e5e5;
    font-size: 8rem;
    margin-bottom: 3.2rem;
}
.people-page .page .no-visitor .title {
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 140%;
}
.people-page .page .online-list .avatar {
    margin-right: 0.8rem;
}
.people-page .page .online-list .os-device .icon {
    font-size: 2rem;
}
.people-page .page .online-list .conversation-state .state-content {
    display: inline-block;
}
.people-page .page .online-list td {
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
}
.people-page .page .online-list .avatar-name {
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
}
.people-page .page .online-list .operators {
    width: 11rem;
}
.people-page .page .online-list .operators .without-conversation {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    white-space: nowrap;
}
.people-page .page .online-list .operators .operator-list {
    display: flex;
    align-items: center;
    margin-left: 1rem;
}
.people-page .page .online-list .operators .operator-list .avatar {
    width: 3.4rem;
    height: 3.4rem;
    margin-left: -1rem;
    box-shadow: inset 0px 0px 0px 0.2rem #ffffff;
    padding: 0.2rem;
    margin-right: 0rem;
}
.people-page .page .online-list .operators .operator-list .avatar .avatar-content {
    width: 3rem;
    height: 3rem;
}
.people-page .page .online-list .operators .operator-list .avatar.plus {
    background-color: #007aff;
}
.people-page .page .online-list .operators .operator-list .avatar.plus .avatar-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
}
.people-page .page .online-list .operators .link {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    white-space: nowrap;
}
.people-page .page .online-list .list-menu {
    width: 6rem;
}
.people-page .page .online-list .list-menu .dropdown .dropdown-content {
    right: 0rem;
    left: auto;
}
.people-page .page .online-list .list-menu .dropdown .dropdown-content .text {
    text-align: left;
}
.people-page .page .online-list .list-menu .icon {
    color: #007aff;
    font-size: 2.4rem;
    cursor: pointer;
}
.people-page .page .online-list .actual-link {
    position: relative;
    width: 23rem;
}
.people-page .page .online-list .actual-link .custom-tooltip-wrapper {
    display: inline-block;
}
.people-page .page .online-list .actual-link .custom-tooltip-wrapper .custom-tooltip {
    width: 64rem;
}
.people-page .page .online-list .actual-link .custom-tooltip-wrapper .custom-tooltip .custom-tooltip-content .title {
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #007aff;
    margin-bottom: 0.8rem;
}
.people-page .page .online-list .actual-link .custom-tooltip-wrapper .custom-tooltip .custom-tooltip-content .row {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.4rem;
}
.people-page .page .online-list .actual-link .custom-tooltip-wrapper .custom-tooltip .custom-tooltip-content .row .label {
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    white-space: nowrap;
    margin-right: 0.5rem;
    width: 10rem;
    flex-shrink: 0;
}
.people-page .page .online-list .actual-link .custom-tooltip-wrapper .custom-tooltip .custom-tooltip-content .row .value {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
}

.people-page .page .operator-subtitle {
    margin: 0rem 0rem 2.4rem 0rem;
}
.people-page .page .operator-subtitle h2 {
    font-family: "ABC Normal Medium";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
}
/************** Custom tooltip **************/
.custom-tooltip-wrapper {
    position: relative;
}
.custom-tooltip-wrapper .custom-tooltip {
    display: none;
    padding-top: 1rem;
    position: absolute;
    top: 0rem;
    left: 0rem;
    z-index: 10;
}
.custom-tooltip-wrapper:hover .custom-tooltip {
    display: block;
}
.custom-tooltip-wrapper .custom-tooltip .custom-tooltip-content {
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 1.6rem;
    z-index: 10;
}
.custom-tooltip-wrapper .custom-tooltip .arrow {
    width: 1rem;
    height: 1rem;
    transform: rotate(45deg);
    position: absolute;
    z-index: 100;
    background-color: #ffffff;
    top: 0.5rem;
}

/************** Modal okno ******************/
.ReactModal__Overlay {
    overflow-y: auto;
    z-index: 100;
    background-color: rgba(245, 245, 245, 0.75) !important;
    position: fixed;
    inset: 0px;
}
.ReactModal__Overlay.red {
    background-color: rgba(245, 204, 204, 0.75) !important;
}
.modal {
    min-width: 50rem;
}
.modal.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
}
.modal .modal-content .modal-footer .link {
    font-size: 16px;
    line-height: 28px;
}
.modal .modal-content .modal-footer .btn.blue,
.modal .modal-content .modal-footer .btn.red {
    padding-left: 4rem;
    padding-right: 4rem;
}
.modal .modal-content .modal-footer .btn.red {
    background-color: #ff0000;
    color: #ffffff;
}

/************** Status a priorita ***********/

.status {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 10px;
}
.status.border {
    width: 1.2rem;
    height: 1.2rem;
    border: 3px solid;
}
.status.white-border {
    border-color: #ffffff;
    color: #ffffff;
}
.status.light-blue-border {
    border-color: #ecf5ff;
    color: #ecf5ff;
}
.status.grey-border {
    border-color: #f5f5f5;
    color: #f5f5f5;
}

.status.online {
    background-color: #7ac942;
}
.status.offline {
    background-color: #ff0000;
}
.status.paused {
    background-color: #ff9900;
}

/************** Toast notifications ***********/

.notifications-component {
    top: 0px;
}
.toast-message {
    background: #ffffff;
    border: 1px solid #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 2.4rem 4.7rem 2.4rem 6.4rem;
    width: 35.6rem;
}
.toast-message > .content {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
}
.toast-message > .icon {
    position: absolute;
    top: 2.7rem;
    left: 2.6rem;
    font-size: 22px;
}
.toast-message > .icon.danger {
    color: #ff0000;
}
.toast-message > .icon.success {
    color: #7ac942;
}
.toast-message > .icon.message-plus {
    color: #ff9900;
}
.toast-message > .icon-remove {
    position: absolute;
    font-size: 1.6rem;
    top: 1.6rem;
    right: 1.5rem;
    color: #000000;
    cursor: pointer;
}

/************** Settings chatbox live preview ****************/

.live-preview-wrapper {
    position: relative;
}
.live-preview {
    margin-top: 4rem;
}
.live-preview.move {
    position: absolute;
    right: 0px;
}
.live-preview .state {
    display: flex;
    flex-direction: column;
    margin-bottom: 3.2rem;
}
.live-preview .state .desc {
    margin-bottom: 1.2rem;
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
}
.live-preview .radio-items {
    display: flex;
    flex-direction: row;
}
.live-preview .chat-opened {
    display: flex;
    flex-direction: column;
    width: 40rem;
    height: 54rem;
    padding: 0rem;
}
.live-preview .chat-opened .content {
    display: flex;
    position: relative;
    flex-grow: 1;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
}
.live-preview .chat-opened .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #007aff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    flex-shrink: 0;
    padding: 1.6rem;
}
.live-preview .chat-opened .content .operator-list {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    margin-right: 1.6rem;
}
.live-preview .chat-opened .content .operator-list .avatar {
    width: 3.4rem;
    height: 3.4rem;
    margin-left: -1rem;
    box-shadow: inset 0px 0px 0px 0.2rem #ffffff;
    padding: 0.2rem;
    margin-right: 0rem;
}
.live-preview .chat-opened .content .operator-list .avatar .avatar-content {
    width: 3rem;
    height: 3rem;
}
.live-preview .chat-opened .content .operator-list .avatar.plus {
    background-color: #007aff;
}
.live-preview .chat-opened .content .operator-list .avatar.plus .avatar-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
}
.live-preview .chat-opened .header .text-block {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 1.6rem;
    white-space: nowrap;
    overflow: hidden;
}
.live-preview .chat-opened .header .text-block .main-title {
    font-family: "ABC Normal Medium";
    color: #ffffff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 0.3rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.live-preview .chat-opened .header .text-block .subtitle {
    font-family: "ABC Normal";
    color: #ffffff;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.live-preview .chat-opened .header .text-block .subtitle .status {
    display: inline-block;
    margin-right: 0.4rem;
}
.live-preview .chat-opened .header .menu {
    display: flex;
    align-items: center;
    flex-direction: row;
}
.live-preview .chat-opened .header .menu .icon {
    color: #ffffff;
    font-size: 1.6rem;
    cursor: pointer;
    margin-left: 0.8rem;
}
.live-preview .chat-opened .header .menu .icon:first-child {
    margin-left: 0rem;
}
.live-preview .chat-opened .header .menu .dropdown .dropdown-content {
    left: -24rem;
    right: -2rem;
}
.live-preview .chat-opened .header .menu .dropdown .dropdown-content .powered-by {
    background: #f5f5f5;
    border-radius: 4px;
    padding: 2.4rem;
    text-decoration: none;
    text-align: center;
    margin-top: 2.4rem;
}
.live-preview .chat-opened .header .menu .dropdown .dropdown-content .powered-by .description {
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #808080;
    text-decoration: none;
    margin-bottom: 0.8rem;
    display: block;
}
.live-preview .chat-opened .header .minimize {
    color: #ffffff;
    margin-left: auto;
    cursor: pointer;
}
.live-preview .chat-opened .message-list {
    flex-grow: 1;
    min-height: 0;
    flex-direction: column;
    display: flex;
    flex: 1 1 1px;
    position: relative;
}
.live-preview .chat-opened .message-list-content {
    position: relative;
    display: flex;
    min-height: 0;
    padding: 0rem 1.6rem;
    flex-grow: 1;
    flex-direction: column;
}
.live-preview .chat-opened .message-list-content .message-system-info {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #808080;
    margin: 2rem 0rem;
}
.live-preview .chat-opened .message-list-content .messages-date-separator {
    position: relative;
    margin: 2.4rem 0rem;
    text-align: center;
    padding: 1rem 0rem;
}
.live-preview .chat-opened .message-list-content .messages-date-separator::before {
    content: "";
    height: 0px;
    position: absolute;
    top: 2rem;
    left: 0rem;
    right: 0rem;
    border-bottom: 1px solid #f5f5f5;
    z-index: 0;
}
.live-preview .chat-opened .message-list-content .messages-date-separator .separator-date {
    position: relative;
    display: inline-block;
    background-color: #ffffff;
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #808080;
    z-index: 2;
    padding: 0rem 1rem;
}

.live-preview .chat-opened .message-list .typing-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 1.6rem;
}
.live-preview .chat-opened .message-list .typing-list .typing-icon {
    color: #808080;
    margin-right: 0.8rem;
    font-size: 1.8rem;
}
.live-preview .chat-opened .message-list .typing-list .typing-text {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #808080;
}
.live-preview .chat-opened .message-list .typing-list .avatar {
    margin-right: 0.8rem;
}

.live-preview .message-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 1.2rem 0rem;
}
.live-preview .message-item .message-avatar {
    margin-right: 0.8rem;
}
.live-preview .message-item .message-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 9.4rem;
}
.live-preview .message-item .message-content .message-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.8rem;
}
.live-preview .message-item .message-content .message-header .name {
    font-family: "ABC Normal Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.9rem;
    margin-right: 1rem;
}
.live-preview .message-item .message-content .message-header .date-time {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 2rem;
    color: #a8a8a8;
    cursor: default;
}
.live-preview .message-item .message-content .message {
    white-space: pre-line;
    padding: 0.8rem;
    background: #f5f5f5;
    border-radius: 0px 8px 8px 8px;
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 140%;
    color: #000000;
    max-width: 27.3rem;
}
.live-preview .message-item .message-content .read-by {
    display: flex;
    align-items: center;
    position: relative;
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 2rem;
    color: #a8a8a8;
    cursor: default;
    cursor: pointer;
}
.live-preview .message-item .message-content .read-by .read-by-icon {
    margin-left: 0.6rem;
    color: #007aff;
}
.live-preview .message-item .message-content .read-by .read-by-content {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 2rem;
    left: 0rem;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);
    padding: 1rem;
}
.live-preview .message-item .message-content .read-by .read-by-content .item {
    display: flex;
    flex-direction: row;
}
.live-preview .message-item .message-content .read-by .read-by-content .item .name {
    white-space: nowrap;
    min-width: 20rem;
}
.live-preview .message-item .message-content .read-by .read-by-content .item .date-time {
    white-space: nowrap;
}

.live-preview .message-item .message-content .rating {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 0.8rem;
    cursor: pointer;
}
.live-preview .message-item .message-content .rating .rating-item {
    display: flex;
    align-items: center;
    background: #f5f5f5;
    border-radius: 4px;
    padding: 8px;
    margin-right: 0.8rem;
    margin-bottom: 0.8rem;
}
.live-preview .message-item .message-content .rating .rating-item:hover {
    background-color: #e5e5e5;
}
.live-preview .message-item .message-content .rating .rating-item .icon {
    font-size: 2rem;
    margin-right: 0.8rem;
}
.live-preview .message-item .message-content .rating .rating-item .text {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
}

.live-preview .message-item.sent {
    flex-direction: row-reverse;
}
.live-preview .message-item.sent .message-avatar {
    margin-right: 0rem;
    margin-left: 0.8rem;
}
.live-preview .message-item.sent .message-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.live-preview .message-item.sent .message-content .message-header {
    flex-direction: row-reverse;
    justify-content: flex-end;
}
.live-preview .message-item.sent .message-content .message-header .name {
    margin-right: 0rem;
    margin-left: 1rem;
}
.live-preview .message-item.sent .message-content .message {
    border-radius: 8px 0px 8px 8px;
    max-width: auto;
}
.live-preview .message-item.sent .message-content .read-by .read-by-content {
    left: auto;
    right: 0rem;
}

.live-preview .message-item.received .message-content .message {
    background-color: #d9ebff;
}

.live-preview .chat-opened .respond-panel {
    display: flex;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: 0rem 1.6rem 1.6rem;
    user-select: none;
}
.live-preview .chat-opened .respond-panel .text-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    user-select: none;
    padding: 1.6rem 0rem;
}
.live-preview .chat-opened .respond-panel .text-block .textarea-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    user-select: none;
    padding: 0rem 1.6rem;
}
.live-preview .chat-opened .respond-panel .text-block textarea {
    height: 3.2rem;
    border: none;
    resize: none;
    outline: none;
    flex-grow: 1;
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.8rem;
    user-select: auto;
}
.live-preview .chat-opened .respond-panel .text-block textarea::placeholder {
    color: #a8a8a8;
}

.live-preview .chat-opened .introduction-form {
    display: flex;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: 0rem 1.6rem 1.6rem;
    user-select: none;
    padding: 3.2rem 1.6rem 1.6rem 1.6rem;
    z-index: 10;
}
.live-preview .chat-opened .introduction-form > .form {
    width: 100%;
}
.live-preview .chat-opened .introduction-form > .form .form-group {
    margin-bottom: 1.6rem;
}
.live-preview .chat-opened .introduction-form > .form .send-block {
    display: flex;
    flex-direction: row;
}
.live-preview .chat-opened .introduction-form > .form .send-block .gdpr {
    display: flex;
    flex-grow: 1;
    align-items: center;
}
.live-preview .chat-opened .introduction-form > .form .send-block .gdpr .label {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    margin-left: 0.8rem;
}
.live-preview .chat-opened .introduction-form > .form .send-block .gdpr .link {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
}
.live-preview .chat-opened .introduction-form > .form .send-block .btn {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    margin-left: auto;
}

.live-preview .chat-closed {
    margin-left: auto;
    margin-right: auto;
    margin-top: 3.2rem;
    width: 13.8rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    bottom: 0rem;
    right: 0rem;
    background: #007aff;
    border: 0.2rem solid #ffffff;
    border-radius: 5rem;
    height: 5rem;
}
.live-preview .chat-closed .content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0rem 1.6rem;
}
.live-preview .chat-closed .content .logo {
    color: #ffffff;
    margin-right: 0.9rem;
}
.live-preview .chat-closed .content .text {
    color: #ffffff;
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
}
.live-preview .chat-closed .status {
    position: absolute;
    bottom: 0rem;
    right: -0.2rem;
    border: 2px solid #ffffff;
    border-radius: 50%;
    z-index: 100;
    width: 1.4rem;
    height: 1.4rem;
}
.live-preview .chat-closed .status.online {
    background: #7ac942;
}
.live-preview .chat-closed .status.offline,
.live-preview .chat-closed .status.paused {
    background: #ff0000;
}
.live-preview .chat-closed .unread-messages-count {
    position: absolute;
    top: -0.8rem;
    right: -0.2rem;
    padding: 0rem 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ffffff;
    border-radius: 8px;
    z-index: 100;
    background: #ff0000;
    color: #ffffff;
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
}
/************* hlavička seznamu ******************/
.list-header {
    display: flex;
    align-items: center;
    margin-bottom: 2.4rem;
}
.list-header .search {
    width: 38rem;
}
.list-header .filter {
    width: 28.4rem;
}
.list-header .on-page {
    width: 18rem;
}

/************* tooltipy ******************/
.tooltip-visitor-link {
    max-width: 70rem;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

/************** Obecné nastavení ************/
.form .form-group {
    margin-bottom: 1.6rem;
}

.d-flex {
    display: flex;
}
.flex-row {
    flex-direction: row;
}
.flex-col {
    flex-direction: column;
}
.justify-content-center {
    justify-content: center;
}
.align-items-center {
    align-items: center;
}

.ml-auto {
    margin-left: auto;
}
.mr-auto {
    margin-right: auto;
}
.mt-auto {
    margin-top: auto;
}
.mb-auto {
    margin-bottom: auto;
}
.mb-0 {
    margin-bottom: 0px !important;
}

.rnc__base {
    top: 0px;
}

.disable-resize {
    resize: none;
}

.cursor-pointer {
    cursor: pointer;
}

input::placeholder {
    font-family: "ABC Normal";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: #808080;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.whole-loading {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    flex-grow: 1;
}
.loading .text {
    font-size: 1.5rem;
    font-weight: 700;
}
.spinner {
    margin: 10px auto;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;
}

.spinner > div {
    background-color: #3b7ddd;
    height: 100%;
    width: 6px;
    display: inline-block;
    margin-right: 2px;
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
}
.spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}
.spinner .rect3 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}
.spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}
.spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
        -webkit-transform: scaleY(0.4);
    }
    20% {
        -webkit-transform: scaleY(1);
    }
}

@keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }
    20% {
        transform: scaleY(1);
        -webkit-transform: scaleY(1);
    }
}

@keyframes blink {
    0% {
        opacity: 0.3;
    }
    50% {
        opacity: 0.7;
    }
    100% {
        opacity: 1;
    }
}

/************* Button loading **************/
.spinner-loading {
    animation: rotate 2s linear infinite;
    width: 3rem;
    height: 3rem;
    display: block;
    padding: 0.2rem;
}

.spinner-loading .path {
    stroke: #ffffff;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}

/********** size styles *************/

.h100p {
    height: 100%;
}

.h1 {
    height: 1px !important;
}
.h2 {
    height: 2px !important;
}
.h3 {
    height: 3px !important;
}
.h4 {
    height: 4px !important;
}
.h5 {
    height: 5px !important;
}
.h6 {
    height: 6px !important;
}
.h7 {
    height: 7px !important;
}
.h8 {
    height: 8px !important;
}
.h9 {
    height: 9px !important;
}
.h10 {
    height: 10px !important;
}
.h11 {
    height: 11px !important;
}
.h12 {
    height: 12px !important;
}
.h13 {
    height: 13px !important;
}
.h14 {
    height: 14px !important;
}
.h15 {
    height: 15px !important;
}
.h16 {
    height: 16px !important;
}
.h17 {
    height: 17px !important;
}
.h18 {
    height: 18px !important;
}
.h19 {
    height: 19px !important;
}
.h20 {
    height: 20px !important;
}
.h21 {
    height: 21px !important;
}
.h22 {
    height: 22px !important;
}
.h23 {
    height: 23px !important;
}
.h24 {
    height: 24px !important;
}
.h25 {
    height: 25px !important;
}
.h26 {
    height: 26px !important;
}
.h27 {
    height: 27px !important;
}
.h28 {
    height: 28px !important;
}
.h29 {
    height: 29px !important;
}
.h30 {
    height: 30px !important;
}
.h31 {
    height: 31px !important;
}
.h32 {
    height: 32px !important;
}
.h33 {
    height: 33px !important;
}
.h34 {
    height: 34px !important;
}
.h35 {
    height: 35px !important;
}
.h36 {
    height: 36px !important;
}
.h37 {
    height: 37px !important;
}
.h38 {
    height: 38px !important;
}
.h39 {
    height: 39px !important;
}
.h40 {
    height: 40px !important;
}
.h41 {
    height: 41px !important;
}
.h42 {
    height: 42px !important;
}
.h43 {
    height: 43px !important;
}
.h44 {
    height: 44px !important;
}
.h45 {
    height: 45px !important;
}
.h46 {
    height: 46px !important;
}
.h47 {
    height: 47px !important;
}
.h48 {
    height: 48px !important;
}
.h49 {
    height: 49px !important;
}
.h50 {
    height: 50px !important;
}

.fs1 {
    font-size: 1px !important;
}
.fs2 {
    font-size: 2px !important;
}
.fs3 {
    font-size: 3px !important;
}
.fs4 {
    font-size: 4px !important;
}
.fs5 {
    font-size: 5px !important;
}
.fs6 {
    font-size: 6px !important;
}
.fs7 {
    font-size: 7px !important;
}
.fs8 {
    font-size: 8px !important;
}
.fs9 {
    font-size: 9px !important;
}
.fs10 {
    font-size: 10px !important;
}
.fs11 {
    font-size: 11px !important;
}
.fs12 {
    font-size: 12px !important;
}
.fs13 {
    font-size: 13px !important;
}
.fs14 {
    font-size: 14px !important;
}
.fs15 {
    font-size: 15px !important;
}
.fs16 {
    font-size: 16px !important;
}
.fs17 {
    font-size: 17px !important;
}
.fs18 {
    font-size: 18px !important;
}
.fs19 {
    font-size: 19px !important;
}
.fs20 {
    font-size: 20px !important;
}
.fs21 {
    font-size: 21px !important;
}
.fs22 {
    font-size: 22px !important;
}
.fs23 {
    font-size: 23px !important;
}
.fs24 {
    font-size: 24px !important;
}
.fs25 {
    font-size: 25px !important;
}
.fs26 {
    font-size: 26px !important;
}
.fs27 {
    font-size: 27px !important;
}
.fs28 {
    font-size: 28px !important;
}
.fs29 {
    font-size: 29px !important;
}
.fs30 {
    font-size: 30px !important;
}
.fs35 {
    font-size: 35px !important;
}
.fs40 {
    font-size: 40px !important;
}
.fs45 {
    font-size: 45px !important;
}
.fs50 {
    font-size: 50px !important;
}

@font-face {
    font-family: "ABC Normal";
    src: url("../Fonts/ABC/ABCNormal-Bold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "ABC Normal";
    src: url("../Fonts/ABC/ABCNormal-Heavy.woff2") format("woff2");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "ABC Normal Medium";
    src: url("../Fonts/ABC/ABCNormal-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "ABC Normal Neutral";
    src: url("../Fonts/ABC/ABCNormal-Neutral.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "ABC Normal Normal";
    src: url("../Fonts/ABC/ABCNormal-Normal.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "ABC Normal";
    src: url("../Fonts/ABC/ABCNormal-Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
