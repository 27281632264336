/* XS */
@media (max-width: 575px) {
    .conversation-page .filter-panel .menu .menu-item{font-size: 1.4rem}
    .message-item .message-content .read-by .read-by-content .item .name {min-width: 14rem;}

    .conversation-page .middle-column .header .avatar-block .conversation-state{cursor: default;}   
    .conversation-page .middle-column .respond-panel .text-block .shortcut-list .content{height: 11rem;}
    .conversation-page .middle-column .respond-panel .text-block .shortcut-list table.titles th.shortcut{width: 13rem; max-width: 13rem; min-width: 13rem;}
    .conversation-page .middle-column .respond-panel .text-block .shortcut-list .content table td.shortcut{width: 13rem; max-width: 13rem; min-width: 13rem;}

    .conversation-page .right-column .section .status-list { flex-direction: column; align-items: flex-start;}

    .lightbox .lightbox-box{ position: absolute; top: 1.6rem; right: 1.6rem; bottom: 1.6rem; left: 1.6rem;}
    .lightbox .lightbox-box > .lightbox-content{padding: 2rem 2rem 3.2rem 2rem;}
    .lightbox .lightbox-box > .close-info {bottom: 0.7rem; }

    .modal{ min-width: auto; }
    
}